import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, List, ListItem, Image } from '@chakra-ui/react';
import Logo from '../assets/logo.png';

import * as authActions from '../redux/actions/auth/authActions';

const Header = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  return (
    <Flex
      className='header'
      zIndex='1000'
      padding='3'
      background='yellow.600'
      width='100%'
      background='whiteAlpha.800'
      justifyContent='space-between'
      align='center'>
      <Box as={Link} to='/'>
        <Image src={Logo} alt='MN Table' width='70px' height='70px' />
      </Box>
      <Box>
        <List display='flex' justifyContent='center' align='center'>
          {isAuthenticated ? (
            <ListItem mx='2'>
              <Button
                colorScheme='linkedin'
                onClick={() => dispatch(authActions.logout())}>
                {' '}
                Logout
              </Button>
            </ListItem>
          ) : (
            <ListItem mx='2'>
              <Button as={Link} to='/login' colorScheme='green' variant='solid'>
                Login
              </Button>
            </ListItem>
          )}
        </List>
      </Box>
    </Flex>
  );
};

export default Header;
