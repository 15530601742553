// import { format, getUnixTime, subDays, getDay, set } from 'date-fns';

import axios from 'axios';
import * as types from './types';

import { getCurrentRangeWeek } from '../../../utils/dateMethods';
import { getSanitizeLabelsAndReceipts } from '../../../utils/cleanData';

const unixTime = getCurrentRangeWeek(new Date());

// console.log({
//   unixTime,
// });

const fetchOrders = async (
  offset = 0,
  createdFrom = unixTime.lastFridayUnixTime,
  createdTo = unixTime.currentUnixTime
) => {
  // const unixTime = getCurrentRangeWeek(new Date());
  try {
    const res = await axios('https://app.ecwid.com/api/v3/17493092/orders', {
      params: {
        token: process.env.REACT_APP_ECWID_TOKEN,
        offset,
        createdFrom,
        createdTo,
        // createdFrom: getUnixTime(new Date('1-20-2021')),
        // createdFrom: unixTime.lastFridayUnixTime,
        // createdTo: unixTime.currentUnixTime,
      },
    });

    return res.data;
  } catch (error) {
    console.log({ error });
  }
};

const getAllProducts = async () => {
  try {
    const res = await axios('https://app.ecwid.com/api/v3/17493092/products', {
      params: {
        token: process.env.REACT_APP_PUBLIC_TOKEN,
      },
    });

    const allProducts = res.data.items.map((item) => ({
      productId: item.id,
      categoryIds: item.categoryIds,
    }));

    return allProducts;
  } catch (error) {
    console.log({ error });
  }
};

const handleLargeAmountOfOrders = async (
  offsetArray,
  createdFrom,
  createdTo
) => {
  try {
    let orderResults = [];

    for (let offset of offsetArray) {
      const resultAwait = await fetchOrders(offset, createdFrom, createdTo);
      orderResults.push(...resultAwait.items);
    }

    // console.log({ orderResults });

    return orderResults;
  } catch (error) {
    console.log({ error });
  }
};

export const getAllOrders =
  (
    createdFrom = unixTime.lastFridayUnixTime,
    createdTo = unixTime.currentUnixTime,
    filterByDateOnClose,
    setIsLoading,
    startDateFormat,
    endDateFormat
  ) =>
  async (dispatch) => {
    try {
      const allProducts = await getAllProducts();
      const firstRes = await fetchOrders(0, createdFrom, createdTo);
      const total = firstRes.total;

      let allOrderResults = [];
      let results;

      switch (true) {
        case total <= 200:
          results = await handleLargeAmountOfOrders(
            [100],
            createdFrom,
            createdTo
          );
          allOrderResults.push(...firstRes.items, ...results);
          break;
        case total <= 300:
          results = await handleLargeAmountOfOrders(
            [100, 200],
            createdFrom,
            createdTo
          );
          allOrderResults.push(...firstRes.items, ...results);
          break;
        case total <= 400:
          results = await handleLargeAmountOfOrders(
            [100, 200, 300],
            createdFrom,
            createdTo
          );
          allOrderResults.push(...firstRes.items, ...results);
          break;
        case total <= 500:
          results = await handleLargeAmountOfOrders(
            [100, 200, 300, 400],
            createdFrom,
            createdTo
          );
          allOrderResults.push(...firstRes.items, ...results);
          break;
        case total <= 600:
          results = await handleLargeAmountOfOrders(
            [100, 200, 300, 400, 500],
            createdFrom,
            createdTo
          );
          allOrderResults.push(...firstRes.items, ...results);
          break;
        case total <= 700:
          results = await handleLargeAmountOfOrders([
            100, 200, 300, 400, 500, 600,
          ]);
          allOrderResults.push(...firstRes.items, ...results);
          break;
        case total <= 800:
          results = await handleLargeAmountOfOrders(
            [100, 200, 300, 400, 500, 600, 700],
            createdFrom,
            createdTo
          );
          allOrderResults.push(...firstRes.items, ...results);
          break;
        case total <= 900:
          results = await handleLargeAmountOfOrders(
            [100, 200, 300, 400, 500, 600, 700, 800],
            createdFrom,
            createdTo
          );
          allOrderResults.push(...firstRes.items, ...results);
          break;
        case total <= 1000:
          results = await handleLargeAmountOfOrders([
            100, 200, 300, 400, 500, 600, 700, 800, 900,
          ]);
          allOrderResults.push(...firstRes.items, ...results);
          break;
        default:
          allOrderResults.push(...firstRes.items);
          break;
      }

      // console.log({
      //   allOrderResults,
      // });

      const filterOrders = allOrderResults.filter(
        (order) => !['CANCELLED', 'REFUNDED'].includes(order.paymentStatus)
      );

      const allLabelsAndReceipts = getSanitizeLabelsAndReceipts(
        filterOrders,
        allProducts
      );

      dispatch({
        type: types.GET_ALL_ORDERS,
        payload: {
          allOrders: filterOrders,
          orderTotal: filterOrders.length,
          allLabelsAndReceipts,
          lastFridayDate: startDateFormat
            ? startDateFormat
            : unixTime.LastFridayDate,
          nowDate: endDateFormat ? endDateFormat : unixTime.nowDate,
        },
      });

      if (setIsLoading) {
        setIsLoading(false);
      }

      if (filterByDateOnClose) {
        filterByDateOnClose();
      }
    } catch (err) {
      console.log(err)
      dispatch({
        type: types.ERRORS,
        payload:
          err?.response?.message || 'Something went wrong with order API',
      });
    }
  };
