import { combineReducers } from 'redux';

import orderReducers from './orderReducers';
import authReducers from './authReducers';

const allReducers = combineReducers({
  orders: orderReducers,
  auth: authReducers,
});

export default allReducers;
