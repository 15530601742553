import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  Box,
  Flex,
  Image,
  Center,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { Formik, Field } from 'formik';

import Logo from '../../assets/logo.png';
import veggieBackground from '../../assets/veggie-background.png';

import * as authActions from '../../redux/actions/auth/authActions';

const LoginForm = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const toast = useToast();

  const initialValues = {
    email: '',
    password: '',
  };

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .lowercase()
      .trim()
      .email()
      .required('Email must be valid'),
    password: yup.string().required('Password is required'),
  });

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(authActions.login(values, history, toast, setSubmitting));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <Flex
          as='form'
          justify='center'
          alignItems=' center'
          flexDirection='column'
          height='100vh'
          width='100%'
          zIndex='1'
          background={` linear-gradient(rgba(0,0,0,0.35),rgba(0,0,0,0.35)),url('${veggieBackground}')`}
          padding='3'
          onSubmit={handleSubmit}>
          <Box
            padding='10'
            boxShadow='base'
            borderRadius='4px'
            maxWidth='400px'
            width='100%'
            zIndex='5'
            mx='auto'
            background='whiteAlpha.900'>
            <Center mb='8'>
              <Image src={Logo} alt='MN Table' width='40' textAlign='center' />
            </Center>
            <Box my='3'>
              <Field name='email'>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}>
                    <FormLabel htmlFor='email'>Email</FormLabel>
                    <Input {...field} id='email' />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box my='3'>
              <Field name='password'>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.password && form.touched.password}>
                    <FormLabel htmlFor='password'>Password</FormLabel>
                    <Input type='password' {...field} id='password' />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>

            <Center mt='5'>
              <Button
                width='100%'
                type='submit'
                // onClick={submitForm}

                colorScheme='green'>
                {isSubmitting ? <Spinner color='white.900' /> : '  Submit'}
              </Button>
            </Center>
          </Box>
        </Flex>
      )}
    </Formik>
  );
};

export default LoginForm;
