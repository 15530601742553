const filterLabelItem = (
  orderProductLabelArray,
  item,
  allProductsArray,
  product
) => {
  if (item.quantity > 1) {
    for (let i = 0; i < item.quantity; i++) {
      orderProductLabelArray.push({
        thumbnailUrl: item.smallThumbnailUrl,
        title: item.name,
        size: item.selectedOptions && item.selectedOptions[0].value,
        sku: item.sku,
        notes: product.orderComments || '',
        checkEntree: item.sku.includes('ENTREE') || item.sku.includes('SIDE'),
        category: allProductsArray.filter(
          (product) => product.productId === item.productId
        ),
        selectionTitle: item.selectedOptions && item.selectedOptions[0].selections[0] && item.selectedOptions[0].selections[0].selectionTitle,
      });
    }
  } else {
    orderProductLabelArray.push({
      thumbnailUrl: item.smallThumbnailUrl,
      title: item.name,
      size: item.selectedOptions && item.selectedOptions[0].value,
      sku: item.sku,
      notes: product.orderComments || '',
      checkEntree: item.sku.includes('ENTREE') || item.sku.includes('SIDE'),
      category: allProductsArray.filter(
        (product) => product.productId === item.productId
      ),
      selectionTitle: item.selectedOptions && item.selectedOptions[0].selections[0] && item.selectedOptions[0].selections[0].selectionTitle,
    });
  }
};

const filterOrderItem = (
  singleOrderReceiptArray,
  product,
  allProductsArray
) => {
  if(product?.shippingPerson?.name.toLowerCase() == "elizabeth bell") {
    {console.log(product.items)}
  }
  singleOrderReceiptArray.push({
    fullName: product?.shippingPerson?.name.toLowerCase() || '',
    addressStreet: product?.shippingPerson?.street || '',
    addressCity: product?.shippingPerson?.city || '',
    addressState: product?.shippingPerson?.stateOrProvinceCode || '',
    addressZipcode: product?.shippingPerson?.postalCode || '',

    notes: product.orderComments,
    items: product.items.map((item) => {
      if(item.selectedOptions && item.selectedOptions[0].value.includes('second kit for only $20')) {
        return {
          title: item.name + " (Add a second kit for only $20!)",
          size: "1:1",
          quantity: item.quantity + 1,
          category: allProductsArray.filter(
            (product) => product.productId === item.productId
          ),
        };
      }else {
        return {
          title: item.name,
          size: item.selectedOptions && item.selectedOptions[0].value,
          quantity: item.quantity,
          category: allProductsArray.filter(
            (product) => product.productId === item.productId
          ),
        };
      }
      
    }),
  });
};

export const getSanitizeLabelsAndReceipts = (
  orderDataArray,
  allProductsArray
) => {
  const orderProductLabelArray = [];
  const singleOrderReceiptArray = [];

  // console.log({ orderDataArray });

  orderDataArray.forEach((product) => {
    if (product.items) {
      product.items.forEach((item) => {
        // get all labels
        filterLabelItem(
          orderProductLabelArray,
          item,
          allProductsArray,
          product
        );
      });
    }

    // get all individal orders
    filterOrderItem(singleOrderReceiptArray, product, allProductsArray);
  });

  // order labels
  orderProductLabelArray.sort((a, b) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );

  // order individual orders
  singleOrderReceiptArray.sort((a, b) =>
    a.fullName > b.fullName ? 1 : b.fullName > a.fullName ? -1 : 0
  );

  let listObj = {};

  const singleProductLabelsIndividualLength = orderProductLabelArray.forEach(
    (label) => {
      // console.table({ label });

      // console.log({ label });

      if (listObj[`${label.title} - Size: ${label.size} qty`]) {
        listObj[`${label.title} - Size: ${label.size} qty`]++;
      } else {
        listObj[`${label.title} - Size: ${label.size} qty`] = 1;
      }
    }
  );

  let totalOrder = 0;

  for (const item in listObj) {
    totalOrder += listObj[item];
  }

  listObj = Object.keys(listObj)
    .sort()
    .reduce((obj, key) => {
      obj[key] = listObj[key];
      return obj;
    }, {});

  // console.log({ listObj, totalOrder });

  return {
    orderProductLabelArray,
    singleOrderReceiptArray,
    listObj,
    totalOrder,
  };
};
