import React from 'react';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react';

const ComparsionTable = ({ tableList, allLabels }) => {
  return (
    <Table variant='striped' colorScheme='linkedin'>
      <TableCaption>Total Quantity: {allLabels}</TableCaption>

      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Size</Th>
          <Th> Qty.</Th>
        </Tr>
      </Thead>

      <Tbody>
        {Object.keys(tableList)?.length > 0 &&
          Object.keys(tableList).map((key, index) => {
            const title = key.split(' - ')[0];
            const size = key.split('Size:')[1].replace('qty', '');
            const values = tableList[key];

            return (
              <Tr>
                <Td>{title}</Td>
                <Td>{size.trim() === 'undefined' ? '' : `Size: ${size}`}</Td>
                <Td>{values}</Td>
              </Tr>
            );
          })}
      </Tbody>
    </Table>
  );
};

export default ComparsionTable;
