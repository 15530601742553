import * as types from '../actions/auth/types';

const initialState = {
  isAuthenticated: null,
  user: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return { ...state, isAuthenticated: true, user: action.payload };
    case types.LOGIN_FAIL:
      localStorage.removeItem('user');
      return {
        ...state,
        user: null,
        isAuthenticated: null,
        error: action.payload,
      };
    case types.LOGOUT:
      return { ...state, isAuthenticated: false, user: null, error: null };

    default:
      return state;
  }
};
