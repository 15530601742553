import { format, getUnixTime, subDays, getDay, set } from 'date-fns';

const getResetDate = (date) => {
  return set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
};

const getFormatDate = (date) => {
  return format(date, 'MM-dd-yy EEEE h:mm a');
};

export const getCurrentRangeWeek = (currentDate) => {
  const sunday = 0;
  const monday = 1;
  const tuesday = 2;
  const wednesday = 3;
  const thursday = 4;
  const friday = 5;
  const saturday = 6;

  let lastFriday;
  let resetFridayToMidnight;

  let startFridayCycle;
  let startFridayCycleAtMightnight;

  let currentUnixTime;
  let lastFridayUnixTime;

  const currentDay = getDay(currentDate);

  // console.log({ currentDay });

  switch (currentDay) {
    case sunday:
      startFridayCycle = subDays(new Date(), 2);

      lastFriday = subDays(new Date(), 9);
      resetFridayToMidnight = getResetDate(lastFriday);
      startFridayCycleAtMightnight = getResetDate(startFridayCycle);
      currentUnixTime = getUnixTime(startFridayCycleAtMightnight);
      lastFridayUnixTime = getUnixTime(resetFridayToMidnight);
      return {
        nowDate: getFormatDate(startFridayCycleAtMightnight),
        LastFridayDate: getFormatDate(resetFridayToMidnight),
        currentUnixTime,
        lastFridayUnixTime,
      };
    case monday:
      // CODE FOR 2 WEEKS
      // startFridayCycle = subDays(new Date(), 3);
      // lastFriday = subDays(new Date(), 17);
      // END CODE FOR 2 WEEKS

      startFridayCycle = subDays(new Date(), 3);
      lastFriday = subDays(new Date(), 10);

      resetFridayToMidnight = set(lastFriday, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      startFridayCycleAtMightnight = set(startFridayCycle, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      currentUnixTime = getUnixTime(startFridayCycleAtMightnight);
      lastFridayUnixTime = getUnixTime(resetFridayToMidnight);

      return {
        nowDate: getFormatDate(startFridayCycleAtMightnight),
        LastFridayDate: getFormatDate(resetFridayToMidnight),
        currentUnixTime,
        lastFridayUnixTime,
      };
    case tuesday:
      lastFriday = subDays(new Date(), 4);
      resetFridayToMidnight = getResetDate(lastFriday);
      currentUnixTime = getUnixTime(currentDate);
      lastFridayUnixTime = getUnixTime(resetFridayToMidnight);
      return {
        nowDate: getFormatDate(currentDate),
        LastFridayDate: getFormatDate(resetFridayToMidnight),
        currentUnixTime,
        lastFridayUnixTime,
      };
    case wednesday:
      lastFriday = subDays(new Date(), 5);
      resetFridayToMidnight = getResetDate(lastFriday);
      currentUnixTime = getUnixTime(currentDate);
      lastFridayUnixTime = getUnixTime(resetFridayToMidnight);

      // console.log({
      //   currentDate,
      //   nowDate: getFormatDate(currentDate),
      //   LastFridayDate: getFormatDate(resetFridayToMidnight),
      //   currentUnixTime,
      //   lastFridayUnixTime,
      // });
      return {
        nowDate: getFormatDate(currentDate),
        LastFridayDate: getFormatDate(resetFridayToMidnight),
        currentUnixTime,
        lastFridayUnixTime,
      };
    case thursday:
      lastFriday = subDays(new Date(), 6);
      resetFridayToMidnight = getResetDate(lastFriday);
      currentUnixTime = getUnixTime(currentDate);
      lastFridayUnixTime = getUnixTime(resetFridayToMidnight);
      return {
        nowDate: getFormatDate(currentDate),
        LastFridayDate: getFormatDate(resetFridayToMidnight),
        currentUnixTime,
        lastFridayUnixTime,
      };

    case friday:
      lastFriday = subDays(new Date(), 7);
      resetFridayToMidnight = getResetDate(lastFriday);
      startFridayCycleAtMightnight = getResetDate(currentDate);
      currentUnixTime = getUnixTime(startFridayCycleAtMightnight);
      lastFridayUnixTime = getUnixTime(resetFridayToMidnight);
      return {
        nowDate: getFormatDate(startFridayCycleAtMightnight),
        LastFridayDate: getFormatDate(resetFridayToMidnight),
        currentUnixTime,
        lastFridayUnixTime,
      };
    case saturday:
      startFridayCycle = subDays(new Date(), 1);
      lastFriday = subDays(new Date(), 8);
      resetFridayToMidnight = getResetDate(lastFriday);
      startFridayCycleAtMightnight = getResetDate(startFridayCycle);
      currentUnixTime = getUnixTime(startFridayCycleAtMightnight);
      lastFridayUnixTime = getUnixTime(resetFridayToMidnight);
      return {
        nowDate: getFormatDate(startFridayCycleAtMightnight),
        LastFridayDate: getFormatDate(resetFridayToMidnight),
        currentUnixTime,
        lastFridayUnixTime,
      };
    default:
      lastFriday = subDays(new Date(), 7);
      resetFridayToMidnight = getResetDate(lastFriday);
      currentUnixTime = getUnixTime(currentDate);
      lastFridayUnixTime = getUnixTime(resetFridayToMidnight);
      return {
        nowDate: getFormatDate(currentDate),
        LastFridayDate: getFormatDate(resetFridayToMidnight),
        currentUnixTime,
        lastFridayUnixTime,
      };
  }

  // console.log({
  //     currentDate,
  //     formatCurrentDate: format(currentDate, "MM-dd-yy EEEE K:mm:ss a"),
  //     dayOfWeek: getDay(currentDate),
  //     lastDay: subDays(new Date(), 3),
  //     formatLastDay: format(subDays(new Date(), 3), "MM-dd-yy EEEE K:mm:ss a"),
  //     setTime: set(subDays(new Date(), 3), { hours: 0, minutes: 0, seconds: 0 })
  //     // parseLastDay: getISODay(formatRelative(subDays(new Date(), 3), new Date()))
  // });
};
