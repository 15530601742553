import React from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './redux/store';

import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import LoadingSkeleton from './components/loadingSkeleton';
// import OrderList from './components/orderList';
// import LoginForm from './components/auth/loginForm';

// import PrivateRoute from './routes/privateRoute';
// import Header from './components/header';

// function AuthIsLoaded({ children }) {
//   const { isAuthenticated } = useSelector((state) => state.auth);
//   return isAuthenticated ? children : <LoadingSkeleton />;
// }
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <BrowserRouter>
          <App />
          {/* <Header />
          <Switch>
            <PrivateRoute exact path='/' component={App} />
            <PrivateRoute exact path='/orders' component={OrderList} />
            <Route exact path='/login' component={LoginForm} />
          </Switch> */}
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
