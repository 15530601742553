import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';

import '../styles/orderItemStyles.scss';

const OrderItem = ({ receipt }) => {
  return (
    receipt && (
      <Box className='orderItemContainer'>
        <div className='user_info'>
          <Heading as='h2'>
            {receipt.fullName.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            )}
          </Heading>
          <div className='add_info'>
            {receipt.addressStreet} <br /> {receipt.addressCity}{' '}
            {receipt.addressState} {receipt.addressZipcode}
          </div>
        </div>
        <div class='reciept_content'>
          <div class='note_content'>
            <div className='column-title'>Notes:</div>
            {receipt.notes ? <p>{receipt.notes}</p> : null}
          </div>
          <div className='items_list'>
            <div className='column-title'>
              <div className='label-name'>Item Name</div>
              <div className='label-size'>Size</div>
              <div className='label-qty'>Qty</div>
            </div>

            {receipt?.items &&
              receipt.items.map((item) => (
                <div className={`single-item custom-${item.size}`}>
                  <div className='item-name'>{item.title}</div>
                  <div className='item-size'>{item.size}</div>
                  <div
                    className={`item-qnty ${item.quantity > 1 && 'more_one'}`}>
                    {item.quantity}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Box>
    )
  );
};

export default OrderItem;
