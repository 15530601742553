import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  OrderedList,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { format, getUnixTime, fromUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as orderActions from '../redux/actions/order/orderActions';

import DairyFreeIcon from '../assets/dairy-free.png';
import GlutenFreeIcon from '../assets/gluten-free.png';
import MeatFreeIcon from '../assets/meat-free.png';
import PeanutIcon from '../assets/peanut.png';
import VegetarianIcon from '../assets/vegetarian.png';

import LoadingSkeleton from './loadingSkeleton';
import ComparsionTable from './comparsionTable';
import FilterDateForm from './filterDateForm';

import { getCurrentRangeWeek } from '../utils/dateMethods';

const unixTime = getCurrentRangeWeek(new Date());

const LabelList = () => {
  const dispatch = useDispatch();
  const { allOrders, allLabels, lastFridayDate, tableList, currentDate } =
    useSelector((state) => state.orders);

  const {
    isOpen: comparsionIsOpen,
    onOpen: comparsionOnOpen,
    onClose: comparsionOnClose,
  } = useDisclosure();
  const {
    isOpen: filterByDateIsOpen,
    onOpen: filterByDateOnOpen,
    onClose: filterByDateOnClose,
  } = useDisclosure();

  const [startDate, setStartDate] = useState(
    new Date(fromUnixTime(unixTime.lastFridayUnixTime))
  );
  const [endDate, setEndDate] = useState(
    new Date(fromUnixTime(unixTime.currentUnixTime))
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(orderActions.getAllOrders());

    console.log('RUNNING IN LABELLIST EFFECT');

    //eslint-disable-next-line
  }, [orderActions.getAllOrders]);

  const printOnClick = () => {
    window.print();
  };

  const filterByDateTime = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const startDateFormat = format(startDate, 'MM-dd-yy EEEE h:mm a');
    const endDateFormat = format(endDate, 'MM-dd-yy EEEE h:mm a');

    dispatch(
      orderActions.getAllOrders(
        getUnixTime(startDate),
        getUnixTime(endDate),
        filterByDateOnClose,
        setIsLoading,
        startDateFormat,
        endDateFormat
      )
    );
  };

  return allLabels?.length === 0 ? (
    <LoadingSkeleton />
  ) : (
    <>
      {currentDate !== null && currentDate !== undefined && (
        <div
          className='timeframe'
          style={{
            textAlign: 'center',
            marginBottom: '2rem',
            marginTop: '3rem',
          }}>
          <Flex justify='center' m='2' className='printBtnContainer'>
            <Button
              onClick={filterByDateOnOpen}
              variant='solid'
              colorScheme='orange'
              style={{ margin: 8 }}>
              Filter Labels By Date
            </Button>
            <FilterDateForm
              title='Filter Labels by Date'
              filterByDateIsOpen={filterByDateIsOpen}
              filterByDateOnClose={filterByDateOnClose}
              filterByDateTime={filterByDateTime}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Flex>

          <div>
            Orders from{' '}
            <span style={{ fontWeight: 'bold', color: '#2980b9' }}>
              {lastFridayDate}
            </span>{' '}
            to{' '}
            <span style={{ fontWeight: 'bold', color: '#2980b9' }}>
              {currentDate}
            </span>
          </div>
          <div style={{ fontWeight: 700, marginTop: '1.5rem', fontSize: 20 }}>
            Number of Orders:{' '}
            <span style={{ color: 'green' }}>
              {allOrders && allOrders.length}
            </span>
          </div>
          <div style={{ fontWeight: 700, marginTop: '1.5rem', fontSize: 20 }}>
            Number of Labels:{' '}
            <span style={{ color: 'green' }}>
              {allLabels && allLabels.length}
            </span>
          </div>
          <div
            style={{
              fontWeight: 700,
              fontSize: 12,
              marginTop: '1.5rem',
              color: 'red',
            }}>
            ( FULLY REFUNDED & CANCELLED ORDERS WILL NOT SHOW)
          </div>

          <Flex justify='center' m='2' className='printBtnContainer'>
            <Button
              onClick={printOnClick}
              variant='solid'
              colorScheme='green'
              style={{ margin: 8 }}>
              Print Labels
            </Button>
            <Button
              as={Link}
              to='/orders'
              style={{ margin: 8 }}
              variant='solid'
              colorScheme='blue'>
              Get Order Receipts
            </Button>
          </Flex>

          {allLabels.length > 0 && (
            <Flex justify='center' m='2' className='printBtnContainer'>
              <Button
                onClick={comparsionOnOpen}
                variant='solid'
                colorScheme='purple'
                style={{ marginBottom: 8, marginTop: 0 }}>
                Quantity Comparsion
              </Button>

              <Modal
                isOpen={comparsionIsOpen}
                size={'full'}
                onClose={comparsionOnClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    Quantity Comparsion (Total: {allLabels?.length})
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <ComparsionTable
                      tableList={tableList}
                      allLabels={allLabels?.length}
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      colorScheme='blue'
                      mr={3}
                      onClick={comparsionOnClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Flex>
          )}

          <Box
            style={{
              fontWeight: 700,
              fontSize: 20,
              marginTop: '1.5rem',
            }}
            color='purple.600'>
            <Heading as='h1' style={{ textTransform: 'uppercase' }}>
              Instructions for Printing:
            </Heading>
            <OrderedList listStyleType='none'>
              <ListItem color='blue.500'>
                1) Click on "More setting" in the printing preview
              </ListItem>
              <ListItem color='blue.500'>
                2) Update Margins from "Default" to "None"
              </ListItem>
              <ListItem color='blue.500'>
                3) Update Scale from "Custom" to "Default"
              </ListItem>
            </OrderedList>
            {/* (PRINTING ONLY, please click on "More setting" in the printing
            preview, and update Margins from "Default" to "None") */}
          </Box>
        </div>
      )}
      <Box
        style={{
          width: '100%',
        }}>
        <Flex flexWrap='wrap' justify='start' p='0' id='order-container'>
          {allLabels &&
            allLabels.length > 0 &&
            allLabels.map((item, i) => {
              const titleSplit = item.title.includes('(')
                ? item.title.split('(')
                : [item.title];
              return (
                <React.Fragment key={`${item}-${i}`}>
                  <Flex
                    w='33.33%'
                    p='3'
                    key={`${item.title}-${i}`}
                    flexDirection='column'
                    justify='center'
                    align='center'
                    className='product-item'>
                      <img
                        src={item.thumbnailUrl}
                        style={{ width: 40, height: 40 }}
                        alt={item.title}
                      />
                    {item.category && item.category[0].categoryIds?.length && (
                      <Box
                        as='section'
                        fontSize='sm'
                        color='gray.700'
                        className='iconIndicators'>
                        {item.category[0].categoryIds.map((categoryId, i) => {
                          if (categoryId === 38232157) {
                            return (
                              <img
                                src={GlutenFreeIcon}
                                alt='Gluten Free'
                                key={i}
                              />
                            );
                          }
                          if (categoryId === 38233158) {
                            return (
                              <img src={PeanutIcon} alt='Peanut' key={i} />
                            );
                          }
                          if (categoryId === 39367008) {
                            return (
                              <img
                                src={DairyFreeIcon}
                                alt='Dairy Free'
                                key={i}
                              />
                            );
                          }
                          if (categoryId === 39366507) {
                            return (
                              <img src={MeatFreeIcon} alt='Meat Free' key={i} />
                            );
                          }
                          if (categoryId === 39368009) {
                            return (
                              <img
                                src={VegetarianIcon}
                                alt='Vegetarian'
                                key={i}
                              />
                            );
                          }
                        })}
                      </Box>
                    )}

                    {titleSplit[1] ? (
                      <Heading fontSize='md' textAlign='center'>
                        <Text
                          fontSize='20px'
                          className='product-title'
                          color='green.800'>
                          {titleSplit[0]}
                        </Text>

                        <Text
                          fontSize='sm'
                          color='gray.700'
                          className='indicators'></Text>
                      </Heading>
                    ) : (
                      <Heading
                        fontSize='20px'
                        textAlign='center'
                        color='green.900'
                        className='product-title'>
                        {titleSplit}
                      </Heading>
                    )}
                    {item.selectionTitle && (
                      <Text fontSize='20px'
                      textAlign='center'
                      color='green.900'
                      className='product-title'>
                       Selected: {item.selectionTitle}
                      </Text>
                    )}
                    <Text fontSize='sm'>{item.orderComment}</Text>
                    <Text
                      fontSize='sm'
                      className={`serv-size size-${item.size}`}>
                      {' '}
                      {item.size && `Serves ${item.size} People`}
                    </Text>
                    {item.checkEntree && (
                      <Text className='entree-message'>
                        Remove lid - cover with foil. Bake in oven until
                        internal temperature reaches 165 degrees
                      </Text>
                    )}
                  </Flex>
                  {i % 1 === 10 && <div class='end-product-line'></div>}
                </React.Fragment>
              );
            })}
        </Flex>
      </Box>
    </>
  );
};

export default LabelList;
