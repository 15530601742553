import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  OrderedList,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { format, getUnixTime, fromUnixTime } from 'date-fns';

import * as orderActions from '../redux/actions/order/orderActions';
import '../styles/orderListStyles.scss';

import OrderItem from './orderItem';
import LoadingSkeleton from './loadingSkeleton';
import FilterDateForm from './filterDateForm';

import { getCurrentRangeWeek } from '../utils/dateMethods';

const unixTime = getCurrentRangeWeek(new Date());

const OrderList = () => {
  const dispatch = useDispatch();
  const { allOrderReceipt, lastFridayDate, currentDate, orderTotal } =
    useSelector((state) => state.orders);

  const {
    isOpen: filterByDateIsOpen,
    onOpen: filterByDateOnOpen,
    onClose: filterByDateOnClose,
  } = useDisclosure();

  const [startDate, setStartDate] = useState(
    new Date(fromUnixTime(unixTime.lastFridayUnixTime))
  );
  const [endDate, setEndDate] = useState(
    new Date(fromUnixTime(unixTime.currentUnixTime))
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(orderActions.getAllOrders());

    console.log('RUNNING IN order list EFFECT');

    //eslint-disable-next-line
  }, [orderActions.getAllOrders]);

  const printOnClick = () => {
    window.print();
  };

  const filterByDateTime = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const startDateFormat = format(startDate, 'MM-dd-yy EEEE h:mm a');
    const endDateFormat = format(endDate, 'MM-dd-yy EEEE h:mm a');

    dispatch(
      orderActions.getAllOrders(
        getUnixTime(startDate),
        getUnixTime(endDate),
        filterByDateOnClose,
        setIsLoading,
        startDateFormat,
        endDateFormat
      )
    );
  };

  return allOrderReceipt && allOrderReceipt.length === 0 ? (
    <LoadingSkeleton />
  ) : (
    <>
      {currentDate !== null && currentDate !== undefined && (
        <div
          className='timeframe'
          style={{
            textAlign: 'center',
            marginBottom: '2rem',
            marginTop: '3rem',
          }}>
          <Flex justify='center' m='2' className='printBtnContainer'>
            <Button
              onClick={filterByDateOnOpen}
              variant='solid'
              colorScheme='orange'
              style={{ margin: 8 }}>
              Filter Orders By Date
            </Button>
            <FilterDateForm
              title='Filter Orders by Date'
              filterByDateIsOpen={filterByDateIsOpen}
              filterByDateOnClose={filterByDateOnClose}
              filterByDateTime={filterByDateTime}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Flex>

          <div>
            Orders from{' '}
            <span style={{ fontWeight: 'bold', color: '#2980b9' }}>
              {lastFridayDate}
            </span>{' '}
            to{' '}
            <span style={{ fontWeight: 'bold', color: '#2980b9' }}>
              {currentDate}
            </span>
          </div>
          <div style={{ fontWeight: 700, marginTop: '1.5rem', fontSize: 20 }}>
            Number of Receipts:{' '}
            <span style={{ color: 'green' }}>
              {allOrderReceipt && allOrderReceipt.length}
            </span>
          </div>

          <div
            style={{
              fontWeight: 700,
              fontSize: 12,
              marginTop: '1.5rem',
              color: 'red',
            }}>
            ( FULLY REFUNDED & CANCELLED ORDERS WILL NOT SHOW)
          </div>

          <Flex justify='center' m='2' className='printBtnContainer'>
            <Button
              onClick={printOnClick}
              variant='solid'
              colorScheme='green'
              style={{ margin: 8 }}>
              Print Order Receipts
            </Button>
            <Button
              as={Link}
              to='/'
              style={{ margin: 8 }}
              variant='solid'
              colorScheme='blue'>
              Get Labels
            </Button>
          </Flex>

          <Box
            style={{
              fontWeight: 700,
              fontSize: 20,
              marginTop: '1.5rem',
            }}
            color='purple.600'>
            <Heading as='h1' style={{ textTransform: 'uppercase' }}>
              Instructions for Printing:
            </Heading>
            <OrderedList listStyleType='none'>
              <ListItem color='blue.500'>
                1) Click on "More setting" in the printing preview
              </ListItem>
              <ListItem color='blue.500'>
                2) Update Margins from "Default" to "None"
              </ListItem>
              <ListItem color='blue.500'>
                3) Update Scale from "Custom" to "Default"
              </ListItem>
            </OrderedList>
          </Box>
        </div>
      )}
      <Flex
        alignItems='stretch'
        justifyContent='stretch'
        direction='column'
        className='orderContainer'>
        {allOrderReceipt &&
          allOrderReceipt.length > 0 &&
          allOrderReceipt.map((receipt, i) => (
            <OrderItem key={`${receipt.fullName}-${i}`} receipt={receipt} />
          ))}
      </Flex>
    </>
  );
};

export default OrderList;
