import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { useToast } from '@chakra-ui/react';

import OrderList from './components/orderList';
import LoginForm from './components/auth/loginForm';

import PrivateRoute from './routes/privateRoute';
import Header from './components/header';

import LabelList from './components/labellist';
import './App.css';

import * as authActions from './redux/actions/auth/authActions';

const App = () => {
  const dispatch = useDispatch();

  const history = useNavigate();
  const toast = useToast();

  useEffect(() => {
    dispatch(authActions.verifyAuth(history, toast));
  }, []);

  return (
    <>
      <Header />
      <Routes>
        <Route exact path='/' element={<LabelList/>} />
        <Route exact path='/orders' element={<OrderList/>} />
        <Route exact path='/login' element={<LoginForm/>} />
      </Routes>
    </>
  );
};

export default App;
