import * as types from '../actions/order/types';

const initialState = {
  orderTotal: null,
  allLabels: [],
  allOrderReceipt: [],
  allOrders: [],
  lastFridayDate: null,
  currentDate: null,
  error: null,
  tableList: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_ORDERS:
      return {
        ...state,
        orderTotal: action.payload.orderTotal,
        allLabels: action.payload.allLabelsAndReceipts.orderProductLabelArray,
        allOrderReceipt:
          action.payload.allLabelsAndReceipts.singleOrderReceiptArray,
        allOrders: action.payload.allOrders,
        lastFridayDate: action.payload.lastFridayDate,
        currentDate: action.payload.nowDate,
        tableList: action.payload.allLabelsAndReceipts.listObj,
        error: null,
      };
    case types.ERRORS:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default orderReducer;
