import * as types from './types';
import firebase from '../../../firebase/firebaseConfig';

export const login =
  ({ email, password }, history, toast, setSubmitting) =>
  async (dispatch) => {
    try {
      const res = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: {
          uid: res.user.uid,
          email: res.user.email,
          displayName: res.user.displayName,
        },
      });
      setSubmitting(false);
      toast({
        position: 'top',
        title: 'Login Successfully!',
        status: 'success',
        duration: 3500,
        isClosable: true,
      });
      history('/');
    } catch (err) {
      dispatch({ type: types.LOGIN_FAIL, payload: err.message });

      setSubmitting(false);
      toast({
        position: 'top',
        title: 'Invalid Credentials',
        status: 'error',
        duration: 3500,
        isClosable: true,
      });
    }
  };

export const logout = () => async (dispatch) => {
  try {
    await firebase.auth().signOut();
    dispatch({ type: types.LOGOUT });
  } catch (err) {
    dispatch({
      type: types.LOGIN_FAIL,
      message: err.message,
    });
  }
};

export const verifyAuth = (history, toast) => async (dispatch) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: {
          uid: user.uid,
          email: user.uid,
          displayName: user.displayName,
        },
      });

      history('/');
    } else {
      toast({
        position: 'top',
        title: 'Please login to access this page',
        status: 'error',
        duration: 3500,
        isClosable: true,
      });

      history('/login');
    }
  });
};
