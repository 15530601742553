import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const FilterDateForm = ({
  title,
  filterByDateIsOpen,
  filterByDateOnClose,
  filterByDateTime,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isLoading,
}) => {
  return (
    <Modal
      isOpen={filterByDateIsOpen}
      size={'lg'}
      onClose={filterByDateOnClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={filterByDateTime}>
          <ModalHeader color='blue.500'>
            {' '}
            {title || 'Filter By Dates'}{' '}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <FormControl my={3}>
              <FormLabel htmlFor='start-date'>Start Date</FormLabel>
              <DatePicker
                id='start-date'
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                dateFormat='MMMM d, yyyy h:mm aa'
              />
            </FormControl>
            <FormControl my={3}>
              <FormLabel htmlFor='end-date'>End Date</FormLabel>
              <DatePicker
                id='end-date'
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                dateFormat='MMMM d, yyyy h:mm aa'
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={filterByDateOnClose}>
              Close
            </Button>
            <Button
              colorScheme='blue'
              mr={3}
              type='submit'
              isLoading={isLoading}>
              Filter
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default FilterDateForm;
